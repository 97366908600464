<template>
  <v-row style="width: 90vw">
    <v-col cols="4" v-if="$vuetify.breakpoint.mdAndUp">
      <impact-services :services="services" />
    </v-col>
    <v-col
      cols="12"
      md="8"
      :class="$vuetify.breakpoint.mdAndUp ? '' : 'ml-4'"
      style="padding-right: 0"
    >
      <div align="left" class="mt-7">
        <p
          style="color: #DAE80D"
          class="font-weight-bold"
          v-text="intro.highlighted"
        />
        <div class="mt-5">
          <p
            v-for="(we, index) in intro.we"
            v-bind:key="index"
            v-html="we"
            class="text-color-primary"
            style="margin-bottom: 0"
          />
        </div>
        <p class="text-color-primary mt-5" v-text="intro.challengeHeadline" />
        <v-row>
          <v-col cols="12" xl="12" style="margin-bottom: 15px">
            <v-row
              v-for="(challenge, index) in intro.challenges"
              v-bind:key="challenge + index"
              style="font-size: 21px"
            >
              <v-col cols="1" style="padding-right: 0; max-width: 55px">
                <v-icon style="margin: 0 5px 5px 0" color="accent"
                  >mdi-check-circle-outline</v-icon
                >
              </v-col>
              <v-col style="padding-left: 0">
                <span
                  class="text-color-primary"
                  style="font-size: 21px"
                  v-text="challenge"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import ImpactServices from "@/components/home/ImpactServices";
export default {
  name: "ImpactIntro",
  components: { ImpactServices },
  props: {
    services: {
      type: Array,
      required: true
    },
    intro: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped></style>
