<template>
  <div>
    <v-img
      class="hidden-sm-and-down"
      src="@/assets/ImpactCo_054.jpg"
      height="100vh"
      width="100vw"
    />
    <v-img
      class="hidden-md-and-up"
      contain
      src="@/assets/ImpactCo_054.jpg"
      width="100vw"
    />
  </div>
</template>

<script>
export default {
  name: "ImpactHero"
};
</script>

<style scoped></style>
