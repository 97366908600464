<template>
  <v-container class="primary" style="max-width: 100vw">
    <v-row align="center" justify="center" class="text-md-left">
      <v-spacer />
      <h3 class="font-weight-regular white--text ma-3">{{ text }}</h3>
      <v-spacer />
      <!--

      <v-dialog transition="dialog-bottom-transition" max-width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="my-3 text--primary"
            large
            v-text="button.contact"
          />
        </template>
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar color="primary" dark> {{ contact.title }}</v-toolbar>
            <v-card-text>
              <div class="text-body-1 pa-12">
                <span v-text="contact.text" />
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                text
                href="mailto:contact@impactco.de"
                v-text="button.contactUs"
              />

              <v-btn text @click="dialog.value = false" v-text="button.close" />
            </v-card-actions>
          </v-card>
        </template>

      </v-dialog>
          -->
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ImpactDivider",
  props: {
    text: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped></style>
