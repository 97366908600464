<template>
  <div>
    <v-footer padless color="primary" style="padding: 0 20px 0 20px">
      <v-layout style="height: 65px">
        <p
          class="white--text ma-auto"
          :style="$vuetify.breakpoint.smAndDown ? 'font-size: 15px' : ''"
        >
          {{ footer.copyright }} &copy; {{ new Date().getFullYear() }}
        </p>
        <v-spacer />
        <v-btn
          v-for="button in footer.buttons"
          v-bind:key="button.text"
          outlined
          :x-small="$vuetify.breakpoint.smAndDown"
          color="white"
          class="ma-auto ml-3"
          v-text="button.text"
          :to="button.route"
        ></v-btn>
      </v-layout>
    </v-footer>
  </div>
</template>

<script>
export default {
  name: "Impact-Footer",
  computed: {
    footer() {
      return this.$t("Layout.Footer");
    }
  }
};
</script>

<style scoped></style>
