<template>
  <div>
    <v-row style="margin: 15px 15vw 0 15vw">
      <v-col
        lg="3"
        md="6"
        sm="6"
        xs="12"
        cols="12"
        v-for="(person, index) in aboutUs.team"
        v-bind:key="person.src + index"
      >
        <v-dialog
          transition="dialog-bottom-transition"
          max-width="1000"
          :fullscreen="$vuetify.breakpoint.xsOnly"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-avatar size="130" rounded v-bind="attrs" v-on="on">
              <v-img :src="require('@/assets/' + person.src)" alt="Avatar" />
            </v-avatar>
          </template>
          <template v-slot:default="dialog">
            <v-card>
              <v-toolbar color="primary" dark class="mb-3">
                <span class="ml-5">{{ person.name }}</span>
                <v-spacer />
                <v-btn text fab @click="dialog.value = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <v-row>
                  <v-col cols="12" v-if="$vuetify.breakpoint.smAndDown">
                    <v-avatar class="mt-5" size="200" rounded>
                      <v-img
                        :src="require('@/assets/' + person.src)"
                        alt="Avatar"
                      />
                    </v-avatar>
                  </v-col>
                  <v-col cols="12" md="9" lg="9" xl="9">
                    <v-card flat>
                      <v-card-title
                        style="color: #DAE80D; text-align: left; font-size: 18px; word-break: break-word"
                        v-text="person.subtitle"
                      />
                      <v-card-text
                        class="text-color-primary"
                        style="text-align: justify; font-size: 16px"
                        v-html="person.text"
                      ></v-card-text>
                      <v-card-text
                        class="text-color-primary px-10"
                        style="text-align: justify; font-style: italic; font-size: 16px"
                        v-html="person.quote"
                      ></v-card-text>
                      <v-card-title
                        style="color: #DAE80D; text-align: left; font-size: 18px; word-break: break-word"
                        v-text="person.slogan"
                      />
                    </v-card>
                  </v-col>
                  <v-col cols="3" v-if="$vuetify.breakpoint.smAndUp">
                    <v-avatar class="mt-5" size="200" rounded>
                      <v-img
                        :src="require('@/assets/' + person.src)"
                        alt="Avatar"
                      />
                    </v-avatar>
                  </v-col>
                </v-row>
                <v-card-title
                  class="text-color-primary"
                  style="word-break: break-word"
                  v-text="person.more.title"
                >
                </v-card-title>
                <v-row>
                  <v-col> </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-expansion-panels>
                      <v-expansion-panel
                        class="text-color-primary"
                        v-for="expansion in person.more.expansions"
                        v-bind:key="expansion.title"
                      >
                        <v-expansion-panel-header>
                          <template v-slot:actions>
                            <v-icon color="primary">
                              $expand
                            </v-icon>
                          </template>
                          <span
                            v-text="expansion.title"
                            style="font-size: 16px"
                          ></span
                        ></v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <ul>
                            <li
                              v-for="point in expansion.points"
                              v-bind:key="point.substr(0, 5)"
                              style="font-size: 16px;"
                            >
                              {{ point }}
                            </li>
                          </ul>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
        </v-dialog>
        <v-row class="mt-4" align-content="center" justify="center">
          <p class="text-color-primary" v-text="person.name" />
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ImpactAboutUs",
  props: {
    aboutUs: {
      type: Object,
      required: true
    },
    button: {
      type: String,
      required: true
    }
  },
  data: () => ({
    dialog: false
  })
};
</script>

<style scoped>
ul {
  list-style: none; /* Remove default bullets */
  text-align: left;
}
ul li::before {
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #dae80d; /* Change the color */
  font-weight: bold; /* bold */
  font-size: 16px;
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1.2em; /* Also needed for space (tweak if needed) */
}
</style>
