<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on }">
      <v-btn color="primary" v-on="on" icon>
        <v-icon>
          mdi-web
        </v-icon>
      </v-btn>
    </template>
    <v-list v-model="$i18n.locale">
      <v-list-item :disabled="$i18n.locale === 'de'" @click="setLang('de')">
        <v-list-item-title
          :class="$i18n.locale !== 'de' ? 'text-color-primary' : ''"
          >Deutsch</v-list-item-title
        >
      </v-list-item>
      <v-list-item :disabled="$i18n.locale === 'en'" @click="setLang('en')">
        <v-list-item-title
          :class="$i18n.locale !== 'en' ? 'text-color-primary' : ''"
          >English</v-list-item-title
        >
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { EventBus } from "@/event-bus";

export default {
  name: "ImpactLanguage",
  methods: {
    setLang(lang) {
      this.$vuetify.lang.current = lang;
      this.$i18n.locale = lang;
      this.storage.lang = lang;
    }
  },
  created() {
    // adds the event listener function that will handle the event
    EventBus.$on("languageLoaderDone", () => {
      if (this.storage.lang) {
        const lang = this.storage.lang;
        // console.log("Test: " + lang);
        this.$vuetify.lang.current = lang;
        this.$i18n.locale = lang;
      }
    });
  },
  beforeDestroy() {
    // removes event listener
    EventBus.$off("languageLoaderDone");
  }
};
</script>

<style scoped></style>
