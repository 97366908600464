<template>
  <div>
    <v-layout align-content-center justify-center class="mb-5">
      <v-img
        max-width="70vw"
        :src="require('@/assets/' + work.src)"
        alt="Action Bild"
      />
    </v-layout>
    <div align="left" style="margin: 0 15vw 0 15vw" class="text-color-primary">
      <p v-html="work.text" />
      <p
        v-for="(we, index) in work.we"
        v-bind:key="index"
        v-html="we"
        class="text-color-primary"
        style="margin-bottom: 0"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "ImpactWork",
  props: {
    work: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped></style>
