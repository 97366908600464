<template>
  <v-layout align-center justify-center>
    <div class="impactTitle">
      <img
        v-if="src"
        :src="require('@/assets/' + src)"
        alt="Title Logo"
        width="20%"
      />
      <span v-if="text" class="headline" v-html="text" />
    </div>
  </v-layout>
</template>
<script>
export default {
  name: "ImpactTitle",
  props: {
    text: {
      type: String
    },
    src: {
      type: String
    }
  }
};
</script>

<style scoped lang="stylus">
.impactTitle
  margin-top 1%
</style>
