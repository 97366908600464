<template>
  <div class="privacy">
    <v-card flat align="left">
      <v-card-title v-text="data.title" class="text-color-primary" />
      <v-card-text class="text-color-primary">
        <p
          style="margin-bottom: 0; font-size: 16px"
          class="text-color-primary"
          v-for="address in data.address"
          v-text="address"
          v-bind:key="address"
        />
        <br />
        <div v-for="body in data.body" v-bind:key="body.headline">
          <h3 v-text="body.headline" style="font-size: 18px"></h3>
          <div v-for="bodyText in body.text" v-bind:key="bodyText.headline">
            <h3 v-text="bodyText.headline" style="font-size: 18px" />
            <div
              v-for="(question, index) in bodyText.questions"
              v-bind:key="index"
            >
              <h4
                style="font-size: 15px"
                v-text="question.title"
                v-if="question.title"
              />
              <p
                style="font-size: 16px"
                v-html="question.text"
                v-if="question.text"
              />
              <ul>
                <li
                  style="font-size: 16px"
                  v-for="point in question.points"
                  v-text="point"
                  v-bind:key="point"
                />
              </ul>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Privacy",
  computed: {
    data() {
      return this.$t("Privacy");
    }
  }
};
</script>

<style scoped lang="stylus">
.privacy {
  color: var(--v-primary-base) !important
  margin-top: 120px;
}
</style>
