<template>
  <v-btn
    fab
    bottom
    right
    fixed
    style="margin-bottom: 39px"
    @click="topFunction"
  >
    <v-icon>mdi-chevron-up</v-icon>
  </v-btn>
</template>

<script>
import goTo from "vuetify/es5/services/goto";

export default {
  name: "ImpactFab",
  methods: {
    topFunction() {
      goTo(0);
    }
  }
};
</script>

<style scoped></style>
