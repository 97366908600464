<template>
  <v-layout column justify-center style="padding: 0 20% 0 20%">
    <div v-for="(service, index) in services" v-bind:key="service.icon + index">
      <v-row>
        <v-col cols="12" style="margin: 12px 0 12px 0">
          <v-btn
            large
            icon
            @click="jumpTo('services')"
            class="elevation-2 mb-2"
          >
            <v-icon color="primary" large>mdi-{{ service.icon }}</v-icon>
          </v-btn>
          <!-- TODO -->
          <p class="text-body-1 text-color-primary">{{ service.text }}</p>
          <v-divider v-if="index !== services.length - 1" />
        </v-col>
      </v-row>
    </div>
  </v-layout>
</template>

<script>
export default {
  name: "ImpactServices",
  props: {
    services: {
      type: Array,
      required: true
    }
  },
  methods: {
    jumpTo(id) {
      const yOffset = -70;
      const element = document.getElementById(id);
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }
};
</script>

<style scoped></style>
