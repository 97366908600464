import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Imprint from "../views/Imprint.vue";
import Privacy from "@/views/Privacy";
import { loadLanguageAsync } from "@/plugins/i18n";
import { EventBus } from "@/event-bus";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/impressum",
    name: "Imprint",
    component: Imprint
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy
  }
];

const router = new VueRouter({
  scrollBehavior: to => {
    if (to.hash) {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve({ selector: to.hash });
        }, 1000);
      });
    } else {
      return { x: 0, y: 0 };
    }
  },
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async function(to, from, next) {
  let languagesLoaded = 2;
  await loadLanguageAsync("en").then(async () => {
    languagesLoaded--;
  });

  await loadLanguageAsync("de").then(async () => {
    languagesLoaded--;
  });

  if (languagesLoaded === 0) {
    console.log("languageLoaderDone");
    EventBus.$emit("languageLoaderDone", languagesLoaded);
    next();
  } else {
    console.log("Failed to load " + languagesLoaded + " languages");
  }
});

// https://github.com/MatteoGabriele/vue-analytics
if (window.localStorage.GoogleAnalytics === "true") {
  console.log("GA-T " + window.localStorage.GoogleAnalytics);
  Vue.use(require("vue-analytics").default, {
    id: "UA-194159194-1",
    router,
    autoTracking: {
      page: true
    }
  });
}

export default router;
