<template v-slot:default="dialog">
  <v-card>
    <v-toolbar color="primary" dark>
      {{ contact.title }} <v-spacer />
      <v-btn color="white" text icon @click="closeDialog()"
        ><v-icon>mdi-close</v-icon></v-btn
      ></v-toolbar
    >
    <v-card-text class="mt-4">
      <div class="text-body-1 pa-6">
        <span class="text-color-primary" v-html="contact.text" />
      </div>
      <v-row>
        <v-col
          lg="3"
          md="6"
          sm="6"
          cols="12"
          v-for="(person, index) in aboutUs.team"
          v-bind:key="person.src + index"
        >
          <v-card color="primary" class="pb-1">
            <v-avatar width="100%" height="100%" rounded>
              <v-img :src="require('@/assets/' + person.src)" alt="Avatar" />
            </v-avatar>
            <p style="color: white; font-size: 17px" class="mt-3">
              {{ person.name }}
            </p>
            <v-list dense color="primary">
              <!--
              <v-list-item>
                <v-list-item-icon
                  ><v-icon color="white">mdi-phone</v-icon></v-list-item-icon
                >
                <a style="color: white" :href="'tel:' + person.tel">{{
                  person.tel
                }}</a>
              </v-list-item>
              -->
              <v-list-item>
                <v-list-item-icon
                  ><v-icon color="white">mdi-email</v-icon></v-list-item-icon
                >
                <a style="color: white" :href="'mailto:' + person.email">{{
                  person.email
                }}</a>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ContactDialog",
  computed: {
    buttons() {
      return this.$t("Layout.Appbar.Buttons");
    },
    contact() {
      return this.$t("Contact");
    },
    button() {
      return this.$t("buttons");
    },
    aboutUs() {
      return this.$t("Home.AboutUs");
    }
  },
  methods: {
    closeDialog() {
      this.$emit("closeContactDialog");
    }
  }
};
</script>

<style scoped></style>
