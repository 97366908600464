<template>
  <div class="home">
    <impact-hero />
    <impact-title :text="headlines[0].text" :src="headlines[0].src" />
    <impact-intro :services="services" :intro="intro" />
    <impact-divider
      :text="dividers[0]"
      :button="buttons"
      :contact="contactDialog"
    />
    <impact-our-services
      id="services"
      :services="ourServices"
      :button="buttons.close"
    />
    <impact-divider
      :text="dividers[1]"
      :button="buttons"
      :contact="contactDialog"
      class="mb-5 mt-5"
    />
    <impact-work id="work" :work="aboutUs" />
    <impact-divider
      :text="dividers[2]"
      :button="buttons"
      :contact="contactDialog"
      class="mb-5 mt-5"
    />
    <impact-about-us :button="buttons.close" id="about" :about-us="aboutUs" />
    <v-img
      :src="require('@/assets/' + footer.src)"
      height="100%"
      alt="Passion led us here"
    />
    <v-dialog
      v-model="adDialogModel"
      transition="dialog-bottom-transition"
      :fullscreen="this.$vuetify.breakpoint.smAndDown"
      :max-width="this.$vuetify.breakpoint.mdAndDown ? '600' : '600'"
    >
      <ad-dialog v-on:closeAdDialog="adDialogModel = false" />
    </v-dialog>
  </div>
</template>

<script>
import ImpactHero from "@/components/home/ImpactHero";
import ImpactTitle from "@/components/home/ImpactTitle";
import ImpactIntro from "@/components/home/ImpactIntro";
import ImpactDivider from "@/components/home/ImpactDivider";
import ImpactOurServices from "@/components/home/ImpactOurServices";
import ImpactAboutUs from "@/components/home/ImpactAboutUs";
import ImpactWork from "@/components/home/ImpactWork";
import AdDialog from "@/components/dialogs/AdDialog";
export default {
  name: "Home",
  components: {
    ImpactWork,
    ImpactAboutUs,
    ImpactOurServices,
    ImpactDivider,
    ImpactIntro,
    ImpactTitle,
    ImpactHero,
    AdDialog
  },
  data: () => ({
    adDialogModel: false
  }),
  methods: {},
  computed: {
    footer() {
      return this.$t("Layout.Footer");
    },
    headlines() {
      return this.$t("Home.headlines");
    },
    contactDialog() {
      return this.$t("Contact");
    },
    services() {
      return this.$t("Home.services");
    },
    ourServices() {
      return this.$t("Home.ourServices");
    },
    intro() {
      return this.$t("Home.Intro");
    },
    buttons() {
      return this.$t("buttons");
    },
    dividers() {
      return this.$t("Home.dividers");
    },
    aboutUs() {
      return this.$t("Home.AboutUs");
    },
    button() {
      return this.$t("buttons");
    }
  },
  mounted() {
    this.adDialogModel = false; // this.storage.cookie;
  },
  watch: {
    "storage.cookie"() {
      console.log("Cookie Changed", this.storage.cookie);
      this.adDialogModel = false; // this.storage.cookie;
    }
  }
};
</script>
