<template v-slot:default="dialog">
  <v-card>
    <v-toolbar color="primary" dark>
      <span class="pl-8">{{ adDialog.title }}</span> <v-spacer />
      <v-btn color="white" class="mr-7" text icon @click="closeDialog()"
        ><v-icon>mdi-close</v-icon></v-btn
      ></v-toolbar
    >
    <v-img src="@/assets/Action.jpg" :alt="adDialog.pictureAlt"> </v-img>
    <v-toolbar color="accent" dark>
      <span class="pl-8 text text-color-primary"> {{ adDialog.subtitle }}</span>
      <v-spacer />
    </v-toolbar>
    <v-card-text class="mt-4 pb-0">
      <div class="text-body-1 pa-6 pb-0" style="text-align: start">
        <span class="text-color-primary impact-text" v-html="adDialog.text" />
      </div>
    </v-card-text>
    <v-card-text style="padding-bottom: 67px">
      <v-card-actions v-if="$vuetify.breakpoint.mdAndUp">
        <v-card-title class="text-color-primary" v-html="adDialog.subtitle2" />
        <v-spacer></v-spacer>
        <v-btn
          href="https://www.linkedin.com/company/impact-companions/"
          outlined
          color="primary"
          target="_blank"
        >
          {{ button.more }}
        </v-btn>
      </v-card-actions>
      <div v-if="$vuetify.breakpoint.smAndDown">
        <v-card-actions>
          <v-card-title
            class="text-color-primary"
            v-html="adDialog.subtitle2"
          />
        </v-card-actions>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            href="https://www.linkedin.com/company/impact-companions/"
            outlined
            color="primary"
            target="_blank"
          >
            {{ button.more }}
          </v-btn>
        </v-card-actions>
      </div>
    </v-card-text>
    <v-toolbar
      style="bottom: 0; position: absolute; width: 100%"
      color="primary"
      dark
    >
      <span class="pl-8"> {{ adDialog.text2 }}</span> <v-spacer />
    </v-toolbar>
  </v-card>
</template>

<script>
export default {
  name: "AdDialog",
  data: () => ({}),
  computed: {
    adDialog() {
      return this.$t("Dialogs.ad");
    },
    button() {
      return this.$t("buttons");
    }
  },
  methods: {
    closeDialog() {
      this.$emit("closeAdDialog");
    }
  }
};
</script>

<style scoped lang="stylus">
.impact-text
  font-family Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  font-size 21px
  -moz-osx-font-smoothing grayscale
  color #0b2455
.text-color-primary
  color var(--v-primary-base) !important
</style>
