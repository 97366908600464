<template>
  <v-app class="app">
    <impact-app-bar id="bar" />
    <v-main>
      <impact-cookie @cookieAccepted="cookieAccepted()" />
      <router-view />
    </v-main>
    <impact-fab />
    <impact-footer />
  </v-app>
</template>

<script>
import ImpactAppBar from "@/components/core/ImpactAppBar";
import ImpactFooter from "@/components/core/ImpactFooter";
import ImpactFab from "@/components/core/ImpactFab";
import ImpactCookie from "@/components/core/ImpactCookie";
export default {
  name: "App",
  components: {
    ImpactCookie,
    ImpactFab,
    ImpactFooter,
    ImpactAppBar
  },
  mounted() {
    if (!this.storage.cookie) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "auto";
    }
  },
  methods: {
    cookieAccepted() {
      document.documentElement.style.overflow = "auto";
    }
  }
};
</script>

<style lang="stylus">
.app
  font-family Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  font-size 21px
  -moz-osx-font-smoothing grayscale
  margin 0
  text-align center
  color #0b2455
.text-color-primary
  color: var(--v-primary-base) !important
.text-color-secondary
  color: var(--v-secondary-base) !important
</style>
