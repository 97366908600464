import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { i18n } from "./plugins/i18n";
import vuetify from "./plugins/vuetify";
import "@babel/polyfill";
import ReactiveStorage from "vue-reactive-localstorage";

Vue.config.productionTip = false;

// Set initial values
Vue.use(ReactiveStorage, {
  cookie: false,
  lang: "de",
  GoogleAnalytics: false
});

export const app = new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
