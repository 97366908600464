<template>
  <v-app-bar
    fixed
    :flat="!isScrolling"
    :color="isScrolling ? 'white' : 'transparent'"
    v-scroll="onScroll"
    :extended="!isScrolling"
    elevate-on-scroll
  >
    <a
      href="/"
      :style="
        isScrolling ? 'margin-top: 3px; margin-bottom: 3px' : 'margin-top: 60px'
      "
      class="hidden-sm-and-down"
    >
      <v-avatar tile :size="isScrolling ? '55' : '100'">
        <img src="@/assets/Square_LogoImpactCo.jpg" alt="Impact Co Logo" />
      </v-avatar>
    </a>
    <a href="/" class="hidden-md-and-up">
      <v-avatar tile size="50">
        <img src="@/assets/Square_LogoImpactCo.jpg" alt="Impact Co Logo" />
      </v-avatar>
    </a>
    <div v-if="$vuetify.breakpoint.mdAndUp" :class="isScrolling ? '' : 'ml-9'">
      <v-btn
        outlined
        class="ml-4"
        color="primary"
        v-for="(button, i) in buttons"
        :key="i"
        @click="jumpTo(button.href)"
      >
        <span v-text="button.text" />
      </v-btn>
    </div>
    <v-dialog
      v-model="contactDialogModel"
      transition="dialog-bottom-transition"
      :fullscreen="this.$vuetify.breakpoint.smAndDown"
      :max-width="this.$vuetify.breakpoint.mdAndDown ? '600' : '1100'"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          outlined
          class="ml-4"
          color="primary"
          v-text="button.contact"
        />
      </template>
      <contact-dialog v-on:closeContactDialog="contactDialogModel = false" />
    </v-dialog>
    <v-spacer />
    <ImpactLanguage />
  </v-app-bar>
</template>

<script>
import ContactDialog from "@/components/dialogs/ContactDialog";
import ImpactLanguage from "@/components/core/ImpactLanguage";
export default {
  components: { ImpactLanguage, ContactDialog },
  data: () => ({
    isScrolling: false,
    contactDialogModel: false
  }),

  computed: {
    buttons() {
      return this.$t("Layout.Appbar.Buttons");
    },
    contact() {
      return this.$t("Contact");
    },
    button() {
      return this.$t("buttons");
    }
  },

  methods: {
    onScroll() {
      this.isScrolling =
        (window.pageYOffset || document.documentElement.scrollTop || 0) > 100;
    },
    jumpTo(href) {
      if (this.$route.path !== "/") {
        this.$router.push("/#" + href);
      } else {
        const yOffset = -170;
        const element = document.getElementById(href);
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  }
};
</script>
