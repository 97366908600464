<template>
  <v-alert
    class="alert"
    style="z-index: 99"
    v-model="cookie"
    color="primary"
    colored-border
    border="bottom"
    origin="bottom"
    :min-width="this.$vuetify.breakpoint.smAndDown ? '200px' : '400px'"
    :width="this.$vuetify.breakpoint.smAndDown ? '95vw' : '35vw'"
    :style="
      this.$vuetify.breakpoint.smAndDown
        ? 'margin: 0 2vw 0 2vw'
        : 'margin: 0 35vw 0 35vw'
    "
  >
    <h3 class="headline text-color-primary">
      {{ text.headline }}
    </h3>
    <div class="text-color-primary">
      {{ text.text }}
    </div>
    <v-divider class="my-4 info" style="opacity: 0.22;"></v-divider>

    <v-row>
      <v-spacer />
      <v-col cols="12" md="12" xl="7" style="text-align: end">
        <v-btn
          :small="$vuetify.breakpoint.smAndDown"
          color="primary"
          outlined
          @click="close(false)"
        >
          {{ button.acceptNecessaryCookies }}
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        md="12"
        xl="6"
        :style="$vuetify.breakpoint.xlOnly ? 'max-width: 230px' : ''"
        :class="$vuetify.breakpoint.xlOnly ? 'text-right mr-12' : 'text-right'"
      >
        <v-btn
          :small="$vuetify.breakpoint.smAndDown"
          color="primary"
          @click="close(true)"
        >
          {{ button.acceptAllCookies }}
        </v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
export default {
  name: "ImpactCookie",
  data: () => ({
    cookie: false
  }),
  methods: {
    checkForCookie() {
      if (!this.storage.cookie) {
        this.cookie = true;
      }
    },
    close(google) {
      this.storage.GoogleAnalytics = google;
      this.storage.cookie = "true";
      this.$emit("cookieAccepted");
      this.cookie = false;
    }
  },
  mounted() {
    this.checkForCookie();
  },
  computed: {
    text() {
      return this.$t("Dialogs.cookie");
    },
    button() {
      return this.$t("buttons");
    }
  }
};
</script>

<style scoped lang="stylus">
.alert
  z-index 999;
  position fixed;
  bottom 3vh
  margin 0 15vw 0 15vw
</style>
