import Vue from "vue";
import VueI18n from "vue-i18n";
import axios from "axios";

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: "de",
  fallbackLocale: "de"
});

const loadedLanguages = [];

function setI18nLanguage(lang) {
  i18n.locale = lang;
  document.querySelector("html").setAttribute("lang", lang);
  return lang;
}

export function loadLanguageAsync(lang) {
  if (loadedLanguages.includes(lang)) {
    if (i18n.locale !== lang) setI18nLanguage(lang);
    return Promise.resolve();
  }
  return (
    axios
      .get("https://impactco.de/lang/" + lang + ".json")
      //.get("http://test.impactco.de/lang/" + lang + ".json")
      .then(response => {
        /* ---------------------------------------------- */
        /* LOCAL DEV */
        /* ---------------------------------------------- */
        // msg = loadLocaleMessages(lang);

        /* ---------------------------------------------- */
        /* PRODUCTION */
        /* ---------------------------------------------- */
        let msg = JSON.parse(JSON.stringify(response.data).trim());

        i18n.setLocaleMessage(lang, msg);
        setI18nLanguage(lang);
      })
  );
}
/* ---------------------------------------------- */
/* LOCAL DEV */
/* ---------------------------------------------- */
/*
function loadLocaleMessages(lang) {
  return require("../locales/" + lang + ".json");
}
*/
