<template>
  <v-row justify="center" dense class="mx-12 mt-5">
    <v-col
      cols="12"
      sm="12"
      md="4"
      lg="4"
      xl="2"
      v-for="(service, index) in services"
      v-bind:key="service.src + index"
    >
      <v-dialog
        transition="dialog-bottom-transition"
        max-width="800"
        :fullscreen="$vuetify.breakpoint.xsOnly"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-hover v-slot="{ hover }">
            <v-card v-bind="attrs" v-on="on">
              <v-img
                :src="require('@/assets/services/' + service.src)"
                class="white--text align-end"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                height="200px"
              >
                <v-expand-transition>
                  <div
                    v-if="hover"
                    class="d-flex transition-fast-in-fast-out v-card--reveal white--text ml-2 mt-6"
                    style="height: 100%;"
                  >
                    <v-card color="transparent" flat dark align="left">
                      <v-card-title
                        style="font-size: 18px"
                        v-text="service.text"
                      />
                      <v-card-text
                        v-html="service.hover"
                        style="font-size: 16px"
                        class="white--text"
                      />
                    </v-card>
                  </div>
                </v-expand-transition>
                <v-card-title
                  v-if="!hover"
                  style="font-size: 18px; word-break: normal !important;"
                  v-text="service.text"
                ></v-card-title>
              </v-img>
            </v-card>
          </v-hover>
        </template>
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar color="primary" dark class="mb-3">
              <span>{{ service.text }} </span>
              <v-spacer />
              <v-btn fab icon @click="dialog.value = false"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </v-toolbar>
            <v-card-text
              v-for="click in service.click"
              v-bind:key="click.text"
              align="left"
              class="text-color-primary"
            >
              <h3
                class="mb-1"
                style="font-size: 18px"
                v-if="click.headline"
                v-text="click.headline"
              />
              <span
                v-if="click.text"
                v-html="click.text"
                style="font-size: 16px"
              ></span>
              <v-row dense v-if="click.points" class="mt-2 ml-2">
                <v-col
                  cols="12"
                  v-for="points in click.points"
                  v-bind:key="points.title"
                >
                  <span
                    class="font-weight-bold mr-2"
                    style="font-size: 16px"
                    v-text="points.title"
                  ></span>
                  <span style="font-size: 16px" v-text="points.text" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ImpactOurServices",
  props: {
    services: {
      type: Array,
      required: true
    },
    button: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped>
.v-card--reveal {
  align-items: start;
  bottom: 0;
  justify-content: right;
  position: absolute;
  width: 100%;
}
</style>
