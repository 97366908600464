<template>
  <div class="imprint">
    <v-card flat align="left" class="text-color-primary">
      <v-card-title
        class="text-color-primary"
        style="font-size: 22px"
        v-text="data.title"
      />
      <v-card-text>
        <h3 class="text-color-primary" style="font-size: 18px">
          Angaben gem. § 5 TMG
        </h3>
        <p
          style="margin-bottom: 0; font-size: 16px"
          class="text-color-primary"
          v-for="address in data.address"
          v-text="address"
          v-bind:key="address"
        />
        <p class="text-color-primary" v-text="data.ustIdNr" />
        <div
          class="text-color-primary"
          v-for="body in data.body"
          v-bind:key="body.headline"
        >
          <h3 v-text="body.headline" style="font-size: 18px"></h3>
          <p v-html="body.text" style="font-size: 16px" />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Imprint",
  computed: {
    data() {
      return this.$t("Imprint");
    }
  }
};
</script>

<style scoped lang="stylus">
.imprint {
  margin-top: 120px
  color: var(--v-primary-base) !important
}
</style>
